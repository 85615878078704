.default-submit-btn{
    display: flex!important;
    justify-content: center;
    align-items: center;
    gap: 0px;
}
.default-submit-btn.disabled-btn{
    background: gray!important;
    cursor: pointer!important;
}
.default-submit-btn span{
    min-width: 100%;
}
.default-submit-btn div:last-child{
    margin-right: 5px;
}